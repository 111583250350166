.label {
  margin-bottom: 0.44rem;
  margin-left: 0.31rem;
}

.textInput {
  border-bottom: 1px solid var(--gray-30);
}

.error {
  margin-top: 0.31rem;
  text-align: start;
  color: var(--text-attention);
}
